import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { configs } from '../configs'
import { extractSearchParams } from '../utils/extractSearchParams'
import {
  RaceLeaderboardResults,
  RbiResults,
  BibNameSearchResult,
  IrpResult,
  AzpEventLink
} from '../types.results.reignite'
import { RosterEntry } from '../types.rosters'
import { EventMetadata } from '../types.metadata.reignite'

export const reigniteApi = createApi({
  reducerPath: 'reigniteApi',
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: configs.athleteApiUrl }),
    {
      maxRetries: configs.rtkQueryMaxRetries
    }
  ),
  endpoints: (builder) => ({
    getEventMetadata: builder.query<EventMetadata, number>({
      query: (
        athlinksEventId,
      ) => `/event/${athlinksEventId}/metadata`
    }),
    getEventWideLeaderboardResults: builder.query<RaceLeaderboardResults[], {
      eventCourseId?: number
      eventId: number
      from: number
      limit: number
    }>({
      query: ({
        eventId,
        ...params
      }) => `/event/${eventId}/results?${extractSearchParams(params)}`
    }),
    getIndividualResult: builder.query<IrpResult, {
      bib?: string | number
      entryId?: string | number
      eventCourseId: number
      eventId: number
    }>({
      query: ({
        bib,
        entryId,
        eventCourseId,
        eventId,
      }) => bib
          ? `/event/${eventId}/race/${eventCourseId}/bib/${bib}/result`
          : `/event/${eventId}/race/${eventCourseId}/entry/${entryId}/result`
    }),
    getRaceLeaderboardResults: builder.query<RaceLeaderboardResults, {
      divisionId?: number
      eventCourseId: number
      eventId: number
      from: number
      limit: number
    }>({
      query: ({
        divisionId,
        eventCourseId,
        eventId,
        ...params
      }) => divisionId
          ? `/event/${eventId}/race/${eventCourseId}/division/${divisionId}/results?${extractSearchParams(params)}`
          : `/event/${eventId}/race/${eventCourseId}/results?${extractSearchParams(params)}`
    }),
    getRaceDivisionIntervalResults: builder.query<RbiResults, {
      divisionId: number
      eventCourseId: number
      eventId: number
      intervalId: number
      from: number
      limit: number
    }>({
      query: ({
        divisionId,
        eventCourseId,
        eventId,
        intervalId,
        ...params
      }) => `/event/${eventId}/race/${eventCourseId}/division/${divisionId}/interval/${intervalId}/results?${extractSearchParams(params)}`
    }),
    getResultsBibNameSearch: builder.query<BibNameSearchResult[], {
      eventId: number
      from: number
      limit: number
      term?: string
    }>({
      query: ({
        eventId,
        ...params
      }) => `/event/${eventId}/results/search?${extractSearchParams(params)}`
    }),
    getRoster: builder.query<{
      page: number
      pageSize: number
      results: RosterEntry[]
      total: number
    }, {
      eventCourseId: number
      page: number
      pageSize: number
      term?: string
    }>({
      query: ({
        eventCourseId,
        ...params
      }: {
        eventCourseId: number
        page: number
        pageSize: number
        term?: string
      }) => `/race/${eventCourseId}/roster?${extractSearchParams(params)}`,
    }),
    getAzpEventLink: builder.query<AzpEventLink, {
      azp: string
      azpEventId: string
    }>({
      query: ({
        azp,
        azpEventId,
      }) => `/azp/${azp}/event/${azpEventId}/link`
    }),
  }),
})

export const {
  useGetEventMetadataQuery,
  useGetEventWideLeaderboardResultsQuery,
  useGetIndividualResultQuery,
  useGetRaceDivisionIntervalResultsQuery,
  useGetRaceLeaderboardResultsQuery,
  useGetResultsBibNameSearchQuery,
  useGetRosterQuery,
  useGetAzpEventLinkQuery,
} = reigniteApi