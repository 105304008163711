import { makeStyles } from '@material-ui/core/styles';

import { fonts } from '../../../shared/styles';

export const useStyles = makeStyles(({ palette }) => ({
  description: {
    fontSize: '14px',
    marginBottom: '8px',
    ...fonts.default,
  },
  title: {
    color: palette.grey[800],
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
}));


