import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom';
import {DropDown} from '../../components/shared/Elements';
import _ from 'lodash';
import {getResultsUrl} from '../../utils/resultsHelpers';

const mapStateToProps = (state) => ({
  dispatch: undefined,
  eventResultsFilters: state.eventResultsFilters
});

class EventResultsFiltersComponent extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    display: PropTypes.bool,
    masterId: PropTypes.number,
    masterData: PropTypes.array,
    courseData: PropTypes.array,
    eventResultsFilters: PropTypes.object.isRequired,
    from: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    metaLoading: PropTypes.bool,
    t: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    const {
      eventResultsFilters,
      from,
    } = this.props;

    // basic rules for filters changing:
    // 1. any change on event id (date) will cause a meta request and clear course/division/split
    // 2. any change on course id will clear division/split
    // 3. changes on any filter will reset paging to 0

    const prevFilters = prevProps.eventResultsFilters;
    const filtersChanged = !_.isEqual(eventResultsFilters, prevFilters);

    if(filtersChanged || (eventResultsFilters.course && from !== prevProps.from)) {

      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    const {
      onPage
    } = this.props;

    onPage(0);
  }

  getEventDates(masterData) {
    return masterData
      .map((event) => ({value: event.value, text: event.text}));
  }

  getCourses(courseData = []) {
    const {t} = this.props;

    const allCourses = {value: 0, text: t('All Race Types')};
    const courses  = courseData
      .map((course) => ({value: course.eventCourseId, text: course.eventCourseName}));

    return [allCourses].concat(courses);
  }

  getDivisions(courseId, courseData = []) {
    const {t} = this.props;

    const course = courseData.find((course) => course.eventCourseId === courseId);
    const overallDivision = {value: 0, text: t('Overall')};

    return !course
      ? [overallDivision]
      : course.metadata.divisions.map((division) => ({value: division.id, text: division.name}));
  }

  getSplits(courseId, courseData = []) {
    const {t} = this.props;

    const course = courseData.find((course) => course.eventCourseId === courseId);
    const allSplits = {value: 0, text: t('All Splits')};
    const metaSplits = course
      ? course.metadata.intervals.map((interval) => ({value: interval.id, text: interval.name}))
      : [];

    return [allSplits].concat(metaSplits);
  }

  updateUrl(ids) {
    const {
      history,
      masterId,
      onPage
    } = this.props;
    const {date, course, division, split} = ids;
    const url = getResultsUrl(masterId, date, course, division, split);

    history.push({pathname: url});
    onPage(0);
  }

  renderDateFilter() {
    const {
      eventResultsFilters: {
        date
      },
      masterData,
      isMobile,
      t
    } = this.props;

    const dateOptions = this.getEventDates(masterData);
    const filterStyle = {
      width: isMobile ? '100%' : '25%',
      display: isMobile ? 'block' : 'inline-block',
      paddingBottom: isMobile ? '24px' : 0
    };

    return (
      <div style={filterStyle}>
        <DropDown
          id='eventDate'
          label={t('Event Date')}
          options={dateOptions}
          value={date}
          onChange={(e) => this.updateUrl({date: e.target.value, course: undefined, division: undefined, split: undefined})}
          disabled={false}
          style={{width: '100%'}}
        />
      </div>
    );
  }

  renderCourseFilters() {
    const {
      courseData,
      eventResultsFilters,
      isCTLive,
      isMobile,
      metaLoading,
      t,
    } = this.props;

    if(metaLoading || !courseData) {
      return null;
    }

    const {date, course, division, split} = eventResultsFilters;

    const courseOptions = this.getCourses(courseData);
    const divisionOptions = this.getDivisions(course, courseData);
    const splitOptions = this.getSplits(course, courseData);

    const courseValue = course || 0;
    const divisionValue = division || divisionOptions[0].value;
    const splitValue = split || splitOptions[0].value;

    const courseDisabled = _.size(courseOptions) < 2;
    const divisionDisabled = !course || (_.size(divisionOptions) < 2);
    const splitDisabled = !course || (_.size(splitOptions) < 2) || !isCTLive;

    const filterStyle = {
      width: isMobile ? '100%' : '25%',
      display: isMobile ? 'block' : 'inline-block',
      paddingLeft: isMobile ? 0 : '24px',
      paddingBottom: isMobile ? '24px' : 0
    };

    return (
      <div style={{display: 'inline'}}>
        <div style={filterStyle}>
          <DropDown
            id='race'
            label={t('Race')}
            options={courseOptions}
            value={courseValue}
            onChange={(e) => this.updateUrl({date, course: e.target.value, division: undefined, split: undefined})}
            disabled={courseDisabled}
          />
        </div>
        <div style={filterStyle}>
          <DropDown
            id='division'
            label={t('Division')}
            options={divisionOptions}
            value={divisionValue}
            onChange={(e) => this.updateUrl({date, course, division: e.target.value, split: splitValue})}
            disabled={divisionDisabled}
          />
        </div>
        <div style={filterStyle}>
          <DropDown
            id='split'
            label={t('Split')}
            options={splitOptions}
            value={splitValue}
            onChange={(e) => this.updateUrl({date, course, division: divisionValue, split: e.target.value})}
            disabled={splitDisabled}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      masterData,
      isMobile,
      display,
    } = this.props;

    if(!masterData || (isMobile && !display)) {
      return null;
    }

    return (
      <div>
        {this.renderDateFilter()}
        {this.renderCourseFilters()}
      </div>
    );
  }
}

export const EventResultsFilters = connect(mapStateToProps)(withTranslation()(withRouter(EventResultsFiltersComponent)));
