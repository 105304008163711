import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { styles } from './styles';
import { Banner, AthlinksAds } from '../shared/Banner';
import useInteractiveAds from '../../lib/firebase/useInteractiveAds';

// Ad Overlay component for mobile view.
const AdOverlay = ({ isMobile, athlinksEventId }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const usingInteractiveAds = useInteractiveAds(athlinksEventId);

  useEffect(() => {
    AthlinksAds.init();

    if (isMobile) {
      setIsMobileDevice(true);
    } else {
      setIsVisible(false); // Don't show the ad on non-mobile devices
      return;
    }

    if (usingInteractiveAds) {
      const adShown = Cookies.get('adShown');
      console.log("ad shown", adShown);

      if (!adShown && navigator.cookieEnabled) {
        // Setting the cookie to indicate that the ad has been shown
        Cookies.set('adShown', 'true', { path: '/', secure: true }); // Session cookie (expires when all tabs are closed)
        console.log('Cookie set. AdShown cookie:', Cookies.get('adShown'));
      } else {
        setIsVisible(false);
      }
    } else {
      setIsVisible(false); // Don't show the ad if not using interactive ads
    }
  }, [isMobile, athlinksEventId, usingInteractiveAds]);

  const adUnits = AthlinksAds.adsForPage('MobileAdOverlay');

  if (!isMobileDevice || !isVisible) {
    return null;
  }

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div style={styles.adOverlay}>
      <div style={styles.adContent}>
        {adUnits.map((x) => (
          <div key={x.adKey} style={styles.bannerStyle}>
            <Banner
              data-ad={x.adKey}
              adKey={x.adKey}
              divId={x.divId}
              width={320} 
              height={480}
              style={{ width: 320, height: 480, margin: '0 auto' }}
            />
          </div>
        ))}
      </div>
      <button style={styles.closeButton} onClick={handleClose}>
        Continue to Athlinks
      </button>
    </div>
  );
};

export default AdOverlay;
