import { makeStyles } from '@material-ui/core';
import { colors } from '../../shared/styles';

export const useStyles = makeStyles(() => ({
  profileLocation: {
    display: 'inline-block',
    fontSize: 14,
    color: colors.grey,
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))

export const styles = {
  profileColumn: {
    minWidth: 0, // to allow text to be truncated
  },
  profileCell: {
    display: 'flex',
    alignItems: 'center'
  },
  profilePhotoContainer: {
    display: 'inline-block',
    marginRight: 30,
    width: 48
  },
  profileNameContainer: (isMobile) => ({
    flex: '1 1 auto',
    verticalAlign: 'top',
    width: isMobile ? 160 : 334,
    position: 'relative'
  }),
  profileNameLink: (isMobile) => ({
    display: 'block',
    fontSize: isMobile ? 18 : 21,
    lineHeight: '1.2em',
    color: colors.textColor,
    textDecoration: 'none',
    width: isMobile ? 160 : 334,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'ProximaNovaBold'
  }),
  profileAge: (hasLocation) => ({
    display: 'inline-block',
    float: 'left',
    marginRight: 10,
    paddingRight: 10,
    fontSize: 14,
    color: colors.grey,
    borderRight: hasLocation ? '1px solid '+ colors.greyLine : '',
  }),
  profileLocation: {
    display: 'inline-block',
    fontSize: 14,
    color: colors.grey,
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  profileBib: (hasLocation) => ({
    display: 'inline-block',
    float: 'left',
    marginRight: 10,
    paddingRight: 10,
    fontSize: 14,
    color: colors.grey,
    borderRight: hasLocation ? '1px solid '+ colors.greyLine : '',
  }),
  goalsQuote: {
    fontSize: 14,
    color: colors.grey,
    marginTop: 5,
    lineHeight: '20px'
  }
};
