import _ from 'lodash';
import { colors, fonts } from '../../shared/styles';
import { configs } from '../../configs';

export const headerStyles = {
  headerContainer: {
    position: 'absolute',
    zIndex: 2000,
    top: 0,
    left: 0,
    height: '51px',
    width: '100%',
  },
  headerOverlay: {
    position:'fixed',
    top:0,
    left:0,
    zIndex:110,
    width: '100%'
  },
  headerSlideout: (shouldShow, height) => ({
    position: 'absolute',
    top: 0,
    width: '100%',
    transform : `translateY(${(shouldShow ? 0 : -height)}px)`,
    opacity: shouldShow ? 1 : 0,
    transition: 'transform .4s cubic-bezier(.44,.13,.26,.87), opacity .4s',
    WebkitTransition: 'transform .4s cubic-bezier(.44,.13,.26,.87), opacity .4s'
  })
};

const homeDefaultText = {
  homeTitle: Object.assign({}, fonts.heavy, {
    color: colors.white,
    fontSize: '40px',
    lineHeight: '1em',
  }),
  homeSubtitle: Object.assign({}, fonts.default, {
    color: colors.white,
    opacity: '0.7',
    fontSize: '15px',
  }),
  homeSectionTitle: Object.assign({}, fonts.heavy, {
    margin: '0 0 10px',
    color: colors.grey,
    fontSize: '12px',
    lineHeight: '1em',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
  }),
  homeSectionHead: Object.assign({}, fonts.heavy, {
    margin: '0',
    color: colors.textColor,
    fontSize: '26px',
    lineHeight: '1em',
  }),
  homeBody: Object.assign({}, fonts.default, {
    color: colors.textColor,
    fontSize: '16px',
    lineHeight: '1.375em',
  }),
};
const homeDesktopText = {
  homeTitle: {
    fontSize: '48px',
  },
  homeSubtitle: {
    fontSize: '18px',
  },
  // homeSectionTitle: = {
  //   margin: '0 0 3px',
  //   fontSize: '12px',
  // },
  homeSectionHead: {
    fontSize: '32px',
  },
  homeBody: {
    fontSize: '16px',
  },
};

export const homeText = (isMobile) => {
  const desktopStyle = !isMobile ? homeDesktopText : {};

  return _.merge({}, homeDefaultText, desktopStyle)
};


const defaultStyles = {
  homeBar: (isEven) => ({
    width: '100%',
    padding: '40px 0 50px',
    backgroundColor: (isEven) ? 'transparent' : colors.lightGrey,
  }),
  textContainer: () => ({
    display: 'block',
    marginBottom: '25px',
    textAlign: 'left',
  }),
  img: () => ({
    display: 'block',
    width: '100%',
    borderRadius: '2px',
  }),
  ctaLinkBtnStyle: {
    color: colors.blue,
    fontFamily: 'ProximaNovaRegular',
    fontSize: 16,
  }
};

const tabletStyles = {
  homeBar: (isEven) => ({
    width: '100%',
    padding: '35px 0',
    backgroundColor: (isEven) ? 'transparent' : colors.lightGrey,
  }),

  textContainer: (isEven) => ({
    display: 'inline-block',
    float: (isEven) ? 'left' : 'right',
    width: '60%',
    paddingLeft: (isEven) ? 0 : 20,
    paddingRight: (isEven) ? 20 : 0,
    paddingTop: 10,
    textAlign: 'left',
  }),
  img: (isEven) => ({
    display: 'inline-block',
    float: (isEven) ? 'right' : 'left',
    width: '39%',
    borderRadius: '2px',
  }),
};

const desktopStyles = {
  textContainer: (isEven) => ({
    display: 'inline-block',
    float: (isEven) ? 'left' : 'right',
    width: '50%',
    paddingLeft: (isEven) ? 0 : 50,
    textAlign: 'left',
    padding: 60,
    // position: 'relative',
    // top: '50%',
    // transform: 'translateY(-50%)',
  }),
  img: (isEven) => ({
    display: 'inline-block',
    float: (isEven) ? 'right' : 'left',
    width: '49%',
    borderRadius: '2px',
  }),
};

export const homeHighlights = ({ isMobile, isLandscape }) => {
  const _tabletStyles = isLandscape ? tabletStyles : {};
  const _desktopStyles = !isMobile ? desktopStyles : {};

  return _.merge({}, defaultStyles, _tabletStyles, _desktopStyles)
};

// Join a community styles
const hcTabletStyles = {
  text_holder: {
    display: 'block',
    color: colors.white,
    position: 'absolute',
    top: '30%',
    left: '25%',
    fontSize: '50px',
    fontStyle: 'italic',
    padding: '4px',
    textAlign: 'left',
    width: '10%',
    lineHeight: '50px',
  }
};
export const hcStyles = ({ isLandscape }) => {
  const _tabletStyles = isLandscape ? hcTabletStyles : {};

  return _.merge({}, /*hcDefaultStyles,*/ _tabletStyles)
};

// Hero
export const videoHeroStyles = {
  heroContainer: {
    position: 'relative',
    zIndex: 3,
    width: '100%',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: '80vh',
    minHeight: '450px',
    overflow: 'hidden',
    backgroundImage: `url(${configs.cdnUrl}/images/home/hero.jpg)`,
    backgroundSize: 'cover'
  },
  video: {
    display: 'block',
    height: '100%',
    width: '100vw',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',

    display: 'block',
    height: '100%',
    width: 'auto',
  },
  centerContent: (isMobile) => ({
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: (isMobile) ? '90%' : '70%',
    maxWidth: '690px',
    minWidth: '300px',
  }),
  ownRaceDayForever: {
    marginBottom:'18px'
  },
  painYouEndured: {
    marginBottom:'44px',
    maxWidth:'610px'
  }
};

// Curated events/Blog Posts
export const homeCarouselStyles = {
  carouselContainer: {
    padding: '45px 30px 70px',
  },
  carouselTitleContainer: {
    textAlign: 'center',
    marginBottom: '46px',
  },
  carouselWrap: {
    marginBottom: 50,
     padding:'0 30px'
  },
  titleAction: (isHovered) => ({
    display: 'inline-block',
    padding: '5px',
    color: (isHovered) ? colors.darkBlue : colors.blue,
    fontSize: 14,
    transition: 'all 0.3s',
  }),
  titleActionIcon: {
    display: 'inline-block',
    position: 'relative',
    top: '-2px',
  },
  carouselWrapper: {
    margin: '0 auto',
    width: '100%',
    // padding: '0 20px',
    position: 'relative',
  },
  slide: (isHovered) => ({
    boxSizing: 'border-box',
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: '10px',
    borderRadius: '2px',
    cursor: 'pointer',
    boxShadow: (isHovered) ? '1px 2px 7px 1px '+ colors.grey : '1px 1px 4px '+ colors.grey,
    transition: 'all 0.4s',
  }),
  slideImg:  (imageUrl) => ({
    position: 'relative',
    height: 225,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(rgba(0,0,0,.0), rgba(0,0,0,.75)), url( ${imageUrl} )`,
  }),
  slideTitleContainer: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    textAlign: 'center',
  },
  slideTitleIntro: (isHovered) => ({
    margin: '0 0 2px',
    fontFamily: 'ProximaNovaSemiBold',
    fontSize: 12,
    letterSpacing: '0.7px',
    color: colors.white,
    opacity: (isHovered) ? '1.0' : '0.8',
    textTransform: 'uppercase',
    transition: 'all 0.3s',
  }),
  slideTitle: {
    margin: 0,
    fontFamily: 'ProximaNovaBold',
    fontSize: 28,
    lineHeight: '1.2em',
    color: colors.white,
  },
  slideCaption: {
    position: 'absolute',
    bottom: 4,
    left: 10,
    fontSize: 12,
    color: colors.white,
  },
  slideCopy: (isMobile) => ({
    margin: 0,
    padding: '13px 10px 10px',
    height: (isMobile) ? 135 : 105,
    overflow: 'hidden',

    fontFamily: 'ProximaNovaRegular, sans-serif',
    fontSize: 14,
    color: colors.grey,
    lineHeight: '1.3em',
  }),
  slideCta: (isHovered) => ({
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    padding: '12px 20px',

    fontFamily: 'ProximaNovaBold, sans-serif',
    fontSize: 12,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: (isHovered) ? colors.darkBlue : colors.blue,
    color: colors.white,
    border: 'none',
    borderRadius: 2,

    transition: 'all 0.3s',
    cursor: 'pointer',
  }),
};

// Home Newsfeed
export const fakeNewsfeedStyles = {
  sectionContainer: {
    position: 'relative',
    padding: '0 15px',
    backgroundColor: colors.blue,
    overflow: 'hidden',
    overflowAnchor: 'none',
  },
  sectionBottomGradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 300,
    background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4))',
    pointerEvents: 'none',
  },
  titleContainer: {
    padding: '45px 0',
    textAlign: 'center',
  },
  newsContainer: {
    backgroundColor: colors.lightGrey,
    padding: '20px 20px 0',
    height: 520,
    overflow: 'hidden',
    borderRadius: '2px 2px 0 0',
    boxShadow: `0 10px 10px ${colors.darkGrey2}`,
  },
  fakeCard: {
    display: 'block',
    width: '100%',
    boxShadow: `1px 2px 3px ${colors.greyLine}`,
    borderRadius: 2,

    // animated properties
    maxHeight: 0,
    marginBottom: 0,
    opacity: 0,
    animation: 'slideIn 0.6s ease-in-out forwards, fadeIn 0.5s 0.4s ease-in-out forwards', // defined in /static/scss/base/keyframes.scss

  },
  titleStyle: {
    color: colors.white,
    opacity: '0.6',
  },
  headStyle: {
    color: colors.white
  }
};

export const sliderSettings = {
  navButtonStyle: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    opacity: 0.2,
    transform: 'translateY(-50%)',
    position: 'absolute',
    top: '39%'
  }
}
