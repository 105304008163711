import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { PageTemplate } from '../home/PageTemplate';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import { EventSearch } from './EventSearch';
import { setTermValue, getSearchTerm } from '../../data/SearchTerm';
import { getLastEventSearchQuery } from '../../data/EventSearchStream';
import {defaultEventFilters, getEventSearchFilters} from '../../data/SearchFilters';
import {SearchActionTypes} from '../../actions/types';
import {eventBus} from '../../data/Streams';
import {EVENT_CONTEXT} from '../../data/SearchContext';
import { EventInfoModal } from '../../components/modal/EventInfoModal'

const mapStateToProps = (state) => ({
  dispatch: undefined,
  context: state.search.context
});

class EventSearchPageComponent extends React.Component {
  static propTypes = {
    filters: PropTypes.object,
    results: PropTypes.object,
    searchTerm: PropTypes.string,
    context: PropTypes.string,
    lastEventSearchQuery: PropTypes.object,
    locationRange: PropTypes.string,
    isMobile: PropTypes.bool,
    t: PropTypes.func
  };

  constructor(props, ctx) {
    super(props, ctx);

    const locationRange = props.locationRange && this.isNumeric(props.locationRange)
      ? Number(props.locationRange)
      : 0;

    this.state = {
      locationRange: locationRange || 0,
    };
  }

  componentDidMount() {
    const {
      searchTerm,
      context,
      dispatch
    } = this.props;

    if (searchTerm) {
      setTermValue(searchTerm)
    }

    if (context !== EVENT_CONTEXT) {
      dispatch({
        type: SearchActionTypes.CONTEXT_SET,
        context: EVENT_CONTEXT
      })
      eventBus.publish('search-context', EVENT_CONTEXT);
    }
  }

  isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

  render() {
    const {
      isMobile,
      searchTerm,
      lastEventSearchQuery,
      t,
      eventFilters,
      location,
    } = this.props;
    const defaultFilters = defaultEventFilters(t);
    const filters = {
      ...defaultFilters,
      ...(this.props.filters || eventFilters),
    };
    if (!filters.dateRangeFilter.value.from ||
      !filters.dateRangeFilter.value.to) {
        filters.dateRangeFilter = defaultFilters.dateRangeFilter;
    }

    const ogTitle = searchTerm
      ? t('Athlinks Event Search: {{searchTerm}}', {searchTerm})
      : t('Athlinks Event Search');
    const ogDescription = searchTerm
      ? t('Search Athlinks for events that match {{searchTerm}}', {searchTerm})
      : t('Search and find events on Athlinks');

    return (
      <PageTemplate
        filters={filters}
        searchTerm={searchTerm}
        onSearchInput={this.onSearchInput}
        headerIsFixed={true}
        showSearchTab={'events'}
        isMobile={isMobile}
        showHeaderFilters={true}
        onLocationInput={this.onLocationInputFromHeader}
        searchTab="event">

        <EventInfoModal
          isMobile={isMobile}
          open={location.state?.showModal}
          title={location.state?.modalTitle}
          body={location.state?.modalBody}
        />

        <StandardHeaderTags
          title={ogTitle}
          description={ogDescription}
        />

        <OpenGraphTags
          ogType='website'
          title={ogTitle}
          description={ogDescription}
        />

        <EventSearch
          isMobile={isMobile}
          searchTerm={searchTerm}
          lastEventSearchQuery={lastEventSearchQuery}
          onSearchTermChange={this.onSearchInput}
          filterValues={filters}
        />

      </PageTemplate>
    );
  }

  onSearchInput = ({target: {value}}) => {
    setTermValue(value);
  };
}

export const EventSearchPage = connect(mapStateToProps)(withTranslation()(connectStream({
  searchTerm: getSearchTerm,
  eventFilters: ({ t }) => getEventSearchFilters(t),
  lastEventSearchQuery: getLastEventSearchQuery
})(EventSearchPageComponent, 'events')));
