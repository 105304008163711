import React, { useEffect, useState } from 'react'
import { DialogModal } from './common/DialogModal'
import { Button } from '../shared/Button'

type IProps = {
  actionButtons?: { text: string, redirectUrl: string }[]
  body?: string[]
  history: any
  isMobile: boolean
  onClose?: () => void
  open: boolean
  title: JSX.Element
}

export const EventInfoModal = ({ actionButtons, body, history, isMobile, onClose, open, title }: IProps) => {
  const [showModal, setShowModal] = useState(open)

  const handleClose = () => {
    setShowModal(false)
    onClose?.()
  }

  useEffect(() => {
    setShowModal(open)
  }, [open])

  return (
    <DialogModal
      isMobile={isMobile}
      title={title}
      body={body ? body.map(text => <p>{text}</p>): undefined}
      actions={
        actionButtons && actionButtons.length > 0 ? 
          actionButtons.map(({ text, redirectUrl }) => (
            <Button
              style={{ width: '100%', margin: '10px 0' }}
              text={text}
              onClick={() => history.push(redirectUrl)}
            />
          ))
          :
          <Button
            text='OK'
            onClick={handleClose}
          />
      }
      actionsContainerStyle={{
        padding: '20px',
        ...(actionButtons && actionButtons.length > 1 ?
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '300px',
          } : 
          {
            justifyContent: 'end'
          }
        )        
      }}
      open={showModal}
      onRequestClose={handleClose}
      bodyStyle={{
        marginLeft: '12px'
      }}
      titleStyle={{
        fontFamily: 'ProximaNovaBold'
      }}
    />
  )
}