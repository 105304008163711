import {
  configureStore as createStore
} from '@reduxjs/toolkit'
import rootReducer from '../reducers'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { getUserPrefsEpic } from '../epics/getUserPrefsEpic'
import { setUserPrefsEpic } from '../epics/setUserPrefsEpic'
import { mapCheckEpic } from '../epics/mapCheckEpic'
import { alaskaApi } from '../api/alaskaApi'
import { reigniteApi } from '../api/reigniteApi'
import { resultsApi } from '../api/resultsApi'
import { finisherCertificateApi } from '../api/finisherCertificateApi'

export const configureStore = () => {
  const epicMiddleware = createEpicMiddleware()
  const store = createStore({
    reducer: rootReducer,
    middleware: get => get()
      .concat(alaskaApi.middleware)
      .concat(finisherCertificateApi.middleware)
      .concat(reigniteApi.middleware)
      .concat(resultsApi.middleware)
      .concat(epicMiddleware)
  })
  epicMiddleware.run(
    combineEpics(
      getUserPrefsEpic,
      setUserPrefsEpic,
      mapCheckEpic,
    )
  )
  return store
}
