import { DistanceUnit, IPace } from '@eventops/athlinks-results-model'
import { UnitType } from '@eventops/athlinks-lib-units'

export default function convertPaceDistanceUnit(pace: IPace, unitType: UnitType): IPace {
  const conversions: { [key in UnitType]?: { [key in DistanceUnit]?: DistanceUnit } } = {
    [UnitType.IMPERIAL]: {
      [DistanceUnit.METERS]: DistanceUnit.YARDS,
      [DistanceUnit.HECTA_METERS]: DistanceUnit.HECTA_YARDS,
      [DistanceUnit.KILO_METERS]: DistanceUnit.MILES
    },
    [UnitType.METRIC]: {
      [DistanceUnit.YARDS]: DistanceUnit.METERS,
      [DistanceUnit.HECTA_YARDS]: DistanceUnit.HECTA_METERS,
      [DistanceUnit.MILES]: DistanceUnit.KILO_METERS,
      [DistanceUnit.FEET]: DistanceUnit.METERS
    }
  }

  const distanceUnit = pace.distance.distanceUnit
  const convertedDistanceUnit = conversions?.[unitType]?.[distanceUnit] || distanceUnit

  return {
    ...pace,
    distance: {
      ...pace.distance,
      distanceUnit: convertedDistanceUnit
    }
  }
}
