import PropTypes from 'prop-types';
import {configs} from '../../../configs';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

export const Courses = ({ courses }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  
  const courseMaps = courses.courseMaps || [];
  if(!courseMaps.length) {
    return null;
  }

  const renderedCourseMaps = courseMaps.map((course, i) => {
    const courseURL = course.url;
    const pdfURL = courseURL.replace('https://s3.amazonaws.com/bazu-static/', configs.customHeroImageUrl);

    /*
      Our image service, Imgix, has various image processing options available.
      One of those options is taking a PDF and outputting a page to an image for
      us, so that we can avoid using libraries.
      Format is: <imgixStaticUrl>/path-to-pdf/pdf-name.pdf?page=<what page we want>
      For now, we also add w=1200 (width 1200px) so that we can get a higher quality
      image to start with
    */
    const imgixPreviewRawUrl = courseURL
      .replace(configs.customHeroImageUrl, configs.imgixStaticUrl)
      .replace('https://s3.amazonaws.com/bazu-static/', configs.imgixStaticUrl);
    const imgixPreviewUrl = `${imgixPreviewRawUrl}?page=1&w=1200`;
    const imgAltText = course.name
      ? t(`Course map for {{name}}`, {name: course.name})
      : t('Course map');


    return (
      <div key={i}>
        <Typography className={styles.description}>{course.name || ''}</Typography>
        <a href={pdfURL} target='_blank' rel="noopener noreferrer" style={{display: 'block'}}>
          <img style={{width: '100%'}} src={imgixPreviewUrl} alt={imgAltText}/>
        </a>
      </div>
    );
  });

  return (
    <div>
      <div
        className={styles.title}>
        {t('Course Map', {count: courseMaps.length})}
      </div>

      { renderedCourseMaps }

    </div>
  );
};

Courses.propTypes = {
  courses: PropTypes.object,
};
