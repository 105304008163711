import { makeStyles } from '@material-ui/core';

import { textStyles, fonts } from '../../../shared/styles';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    marginBottom: 24,
  },
  title: {
    color: palette.grey[800],
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.4,
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  locationTitle: {
    ...textStyles.body1Dark,
    lineHeight: '1.4em',
  },
  locationLink: {
    display: 'block',
    marginBottom: 13,
    ...textStyles.textLink2Blue,
    lineHeight: '1.3em',
  },
}))

export { useStyles }
