import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {getEventByEventId} from '../../data/EventStreams';
import {RaceStreams} from '../../data/RaceStreams';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {Loading} from '../../components/shared/Loading';
import {imageStyles} from '../../shared/styles';
import {getResultsUrl} from '../../utils/resultsHelpers';

export class EventLandingPageComponent extends Component {

  static propTypes = {
    master: PropTypes.object,
    course: PropTypes.object,
    eventId: PropTypes.number.isRequired,
    courseId: PropTypes.number,
    t: PropTypes.func
  };

  static defaultProps = {
    master: {isLoading: true},
    courses: {isLoading: true}
  };

  /**
   * Extract master id. If master id is null then master event is not loaded yet
   * @param {boolean} isLoading
   * @param {number} masterId
   * @return {number|null}
   */
  getMasterId = ({isLoading, event: {masterId} = {}}) =>
    isLoading ? null : masterId;

  /**
   * Extracts event course id. If event course is 0 then courses are loaded
   * but event course id does not exist (can this happen?)
   * @param {number} courseId
   * @param {array} courses
   * @return {number|null}
   */
  getEventCourseId = (courseId, courses) => {
    const {isLoading, races} = courses;
    if (!courseId) return 0;
    else if (isLoading) return null;
    else {
      const race = races.find((race) => race.courseId === courseId);
      return race ? race.eventCourseId : 0;
    }
  };

  UNSAFE_componentWillReceiveProps({master, courses}) {
    const {eventId, courseId, history, location} = this.props;
    const masterId = this.getMasterId(master);
    const eventCourseId = this.getEventCourseId(courseId, courses);
    if(!master.isLoading && masterId !== null && eventCourseId !== null) {
      history.push(getResultsUrl(masterId, eventId, eventCourseId), location.state);
    }
  }

  render = () => {
    const {
      t
    } = this.props;

    return (
      <div style={imageStyles.loadingOverlay}>
        <div style={imageStyles.loadingPosition}>
          <Loading timeoutMessage={t('Still thinking...')}/>
        </div>
      </div>
    )
  }

}

/**
 * This is simple redirect component. Finds master id and event course id
 * and redirects to the Master Landing Page
 */
export const EventLandingPage = withTranslation()(connectStream({
  master: ({eventId}) => getEventByEventId(eventId),
  courses: ({eventId}) => RaceStreams.receiveAthlinksCourses({athlinksId: eventId})
})(withRouter(EventLandingPageComponent)));
